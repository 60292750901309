import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/ui/layout/layout'
import Header from '../components/ui/header/header'
import SEO from "../components/seo"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import LeftArrow from "../assets/svg/icons/arrow-left.svg"
import RightArrow from "../assets/svg/icons/arrow-right.svg"
import Edit from "../assets/svg/icons/edit.svg"

import docStyles from "./doc.module.scss"
import "../styles/prism.css";

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        description
        github
      }
      html
      headings {
        depth
        id
        value
      }
    }
  }
`

function Doc(props) {
  const {next, prev} = props.pageContext;
  
  const {
    breadcrumb: { crumbs },
  } = props.pageContext

  const toc = props.data.markdownRemark.headings.map(function(heading) {
    if(heading.depth === 2 || heading.depth === 3) {
      return <a href={`#${heading.id}`} className={`h${heading.depth}`} key={heading.id}>{heading.value}</a>
    }
  });

  return (
    <Layout>
      <SEO title={props.data.markdownRemark.frontmatter.title} description={props.data.markdownRemark.frontmatter.description} />
      <Header/>
      <main id="content">
        <div className={docStyles.container}>
          <div className={docStyles.sidebar}>
            <nav className={docStyles.navigation}>
              <h2>Table of Contents</h2>
              {toc}
            </nav>
          </div>
          <div>
            <Breadcrumb
              crumbs={crumbs}
              crumbLabel={props.data.markdownRemark.frontmatter.title}
            />
            <h1 className={docStyles.title}>{props.data.markdownRemark.frontmatter.title}</h1>
          </div>
          <div className={docStyles.content}>
            <div className="entry-content" dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
            {props.data.markdownRemark.frontmatter.github && <div className={docStyles.githubEdit}><a href={props.data.markdownRemark.frontmatter.github}><Edit /> Edit this page on GitHub</a></div>}
            <div className="page-navigation">
              <div className="page-navigation__prev">
                {prev && <span className="page-navigation__title">Prev</span>}
                {prev &&
                  <Link className="page-navigation__link" to={`/docs/${prev.fields.slug}`}>
                    <LeftArrow />
                    {prev.frontmatter.title}
                  </Link>
                }
              </div>
              <div className="page-navigation__next">
                {next && <span className="page-navigation__title">Next</span>}
                {next &&
                  <Link className="page-navigation__link" to={`/docs/${next.fields.slug}`}>
                    {next.frontmatter.title}
                    <RightArrow />
                  </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Doc